import { v4 as uuid } from 'uuid'

export const themeFactory = (props = {}) => ({
  id: 'foodora',
  name: 'Foodora',
  brand_code: 'fo',
  primary_color: '#D70F64',
  secondary_color: '#FFFFFF',
  logo_icon: '/foodora/foodora-logo.svg',
  logo: '/foodora/foodora-logo.svg',
  bg_image_top: '',
  bg_image_left: '',
  bg_image_right: '',
  bg_image_bottom: '',
  enabled: true,
  created_by: 'user-tbd',
  created_at: '2022-01-27T12:00:42.079721',
  updated_by: 'user-tbd',
  updated_at: '2022-01-27T12:00:42.079732',
  ...props,
})

export const workflowFactory = (props = {}) => ({
  id: uuid(),
  rules:
    'Bogota$de:berlin:berlin$ed69971a-b1f4-440e-855c-1dd26a65381b;Santa Marta$de:berlin:berlin$ed69971a-b1f4-440e-855c-1dd26a65381b;',
  landing_page_content: 'bdbdf302-ae90-4f0c-9501-e0483285e92f',
  enabled: true,
  created_by: 'foodora',
  created_at: '2021-01-31T13:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2021-01-31T13:54:17.410512',
  ...props,
})

export const countryFactory = (props = {}) => ({
  id: 4,
  code: 'de',
  name: 'Germany OLD',
  created_at: '2017-01-16T10:59:19',
  updated_at: '2017-01-16T10:59:19',

  ...props,
})

export const languageFactory = (props = {}) => ({
  id: 'de',
  name: 'Deutsch (German)',
  enabled: true,
  created_by: 'foodora',
  created_at: '2021-01-31T13:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2021-01-31T13:54:17.410512',
  ...props,
})

export const vehicleFactory = (props = {}) => ({
  id: uuid(),
  name: 'walk',
  text: 'Caminador',
  cities: ['Bogota', 'Santa Marta'],
  landing_page_content: 'bdbdf302-ae90-4f0c-9501-e0483285e92f2',
  enabled: true,
  created_by: 'foodora',
  created_at: '2021-01-31T13:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2021-01-31T13:54:17.410512',
  ...props,
})

export const fieldFactory = (props = {}) => ({
  id: uuid(),
  name: 'last_name',
  text: 'Cual es tu apellido??',
  order_index: 0,
  landing_page_content_id: 'bdbdf302-ae90-4f0c-9501-e0483285e92f',
  enabled: true,
  field_value: '',
  hidden: false,
  field_type: 'string',
  settings: {},
  created_by: 'foodora',
  created_at: '2021-01-31T13:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2021-01-31T13:54:17.410512',
  ...props,
})

export const landingPageContentFactory = (
  title = 'Your title',
  props = {},
) => ({
  id: uuid(),
  content: {
    version: 1,
    footer: [
      {
        id: '67ca9e53-3a9e-434c-9923-6c929d58fe0a',
        name: 'Contact',
        links: [
          {
            id: '0e7440eb-d25d-4cc8-b8db-c5c439730d75',
            url: 'https://deliveryhero.com',
            text: 'Address',
          },
        ],
      },
      {
        id: '86dfda87-03c2-4c51-a8e6-87740aae27bf',
        name: 'Team',
        links: [
          {
            id: 'fb11ac79-35e8-40dc-886b-4aca3f57cb47',
            url: 'https://test',
            text: 'Address',
          },
        ],
      },
      {
        id: '02479606-3b86-4d4b-979c-6bc63404904b',
        name: 'Policy',
        links: [
          {
            id: 'c2cb05ed-4090-4f72-a3ed-9e8e644455e7',
            url: 'https://deliveryhero.com',
            text: 'Team',
          },
          {
            id: '43aab88c-e835-4f13-a7e6-882e36556d46',
            url: 'https://test.w',
            text: 'Test',
          },
        ],
      },
    ],
    structure: {
      id: 'e8e501f2-2a98-40c6-8f90-f0d0f11b8603',
      name: 'root',
      type: 'layout',
      children: [
        {
          id: '3ccff2aa-fe97-45c2-976b-6fa3ec850139',
          name: 'header',
          type: 'layout',
          children: [
            {
              id: '84cd3d1d-9109-440e-a85b-ebac8950de2b',
              name: '',
              type: 'text',
              value: '$HEADER',
              properties: { style: { width: '100%' } },
            },
          ],
          properties: {
            item: false,
            style: { width: '100%' },
            container: true,
            direction: 'column',
            responsive: false,
          },
        },
        {
          id: 'c03070f4-39d6-40a9-9833-f7eb9f7cc39b',
          name: 'row1',
          type: 'layout',
          children: [
            {
              id: '66739136-6d83-4cf0-aad8-0cd48552dccf',
              name: '',
              type: 'layout',
              children: [
                {
                  id: '54cb7582-cf59-460b-8db3-e28437f5e161',
                  name: '',
                  type: 'list',
                  value: title,
                  properties: {
                    type: 'bullet',
                    style: { marginBottom: 15 },
                    textAlign: 'left',
                    sectionTitle: true,
                  },
                },
                {
                  id: 'a9fccb82-cde6-4040-a826-f6c214e0f53a',
                  name: '',
                  type: 'text',
                  value: 'Subtitle',
                  properties: {
                    type: 'none',
                    style: { marginBottom: 75 },
                    paragraph: true,
                  },
                },
                {
                  id: '97f6dff8-fe6f-44b8-8cdd-7b5cb3af5233',
                  name: '',
                  type: 'image',
                  value: '/common/upload.png',
                  properties: {
                    style: { display: 'none' },
                    desktop: { style: { display: 'block' } },
                  },
                },
              ],
              properties: {
                sm: 5,
                xs: 10,
                item: true,
                container: true,
                direction: 'column',
                responsive: false,
              },
            },
            {
              id: '518b018b-b263-4bfb-b131-116108031983',
              name: '',
              type: 'layout',
              children: [
                {
                  id: 'f1ff6d6f-f2f2-4cbe-b8f9-b5992b53f330',
                  name: '',
                  type: 'text',
                  value: '$FORM',
                  properties: {},
                },
              ],
              properties: {
                sm: 5,
                xs: 12,
                item: true,
                style: {
                  padding: '40px 60px',
                  justifyContent: 'center',
                  backgroundColor: '$PRIMARY_COLOR',
                },
                container: true,
                direction: 'column',
                responsive: false,
              },
            },
          ],
          properties: {
            item: false,
            style: { width: '100%', marginBottom: 100 },
            spacing: 2,
            container: true,
            direction: 'column',
            responsive: true,
          },
        },
        {
          id: 'c9de4523-a77e-45d9-8673-dfefdbfd03f7',
          name: 'row2',
          type: 'layout',
          children: [
            {
              id: '89e22899-593d-4e92-b7fb-9fe2ac086575',
              name: '',
              type: 'layout',
              children: [
                {
                  id: '23ecbca2-b3cc-47da-8805-1915e2756b7e',
                  name: '',
                  type: 'text',
                  value: 'Pew pew pew\n',
                  properties: {
                    style: { marginBottom: 15 },
                    sectionTitle: true,
                  },
                },
                {
                  id: '32fd25b7-ca71-40b1-80c3-2583e5a13f1c',
                  name: '',
                  type: 'text',
                  value:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum sit amet vehicula lectus, vel porta mi. Nulla luctus felis vitae sapien commodo, at pharetra libero suscipit',
                  properties: { paragraph: true },
                },
              ],
              properties: {
                sm: 5,
                xs: 10,
                item: true,
                desktop: {
                  style: {
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                  },
                },
                container: true,
                direction: 'column',
                responsive: false,
              },
            },
            {
              id: 'a1f8432f-4c9f-42d9-a6ee-67c24f3c0158',
              name: '',
              type: 'layout',
              children: [
                {
                  id: 'faa00741-d644-444a-95f5-6bd2fab9dbe0',
                  name: '',
                  type: 'image',
                  value: '/common/upload.png',
                  properties: {},
                },
              ],
              properties: {
                sm: 5,
                xs: 10,
                item: true,
                container: true,
                direction: 'column',
                responsive: false,
              },
            },
          ],
          properties: {
            item: false,
            style: { width: '100%', marginBottom: 100 },
            spacing: 2,
            container: true,
            direction: 'column',
            responsive: true,
          },
        },
      ],
      properties: {
        item: false,
        container: true,
        direction: 'column',
        responsive: false,
      },
    },
  },
  landing_page: '51b83ac1-4a49-45a5-b333-2aa3d7a2a50b',
  language: languageFactory({ id: 'es', name: 'Español' }),
  vehicles: [
    vehicleFactory({ name: 'walk', text: 'caminante' }),
    vehicleFactory({ name: 'car', text: 'Carro', order_index: 1 }),
    vehicleFactory({ name: 'bike', text: 'Bici', order_index: 2 }),
  ],
  fields: [
    fieldFactory({ name: 'first_name', text: 'Primer Nomber', order_index: 0 }),
    fieldFactory({
      name: 'age',
      text: '8',
      field_type: 'number',
      order_index: 1,
      settings: {
        datePicker: true,
      },
    }),
    fieldFactory({ name: 'phone_number', text: 'Telefono', order_index: 2 }),
  ],
  workflow: workflowFactory(),
  seo: {
    id: '94c2448d-f37e-4fcc-abe9-4730069a83b9',
    title: 'Dodo | Come and join us!',
    tags: ['rider', 'make money', 'moar-money', 'tets'],
    description:
      'This is a great way to increase your income, by becoming a Rider with us!',
    landing_page_content: 'bdbdf302-ae90-4f0c-9501-e0483285e92f',
    enabled: false,
    created_by: 'foodora',
    created_at: '2021-01-31T13:54:17.410512',
    updated_by: 'foodora',
    updated_at: '2021-01-31T13:54:17.410512',
  },
  enabled: true,
  created_by: 'foodora',
  created_at: '2021-01-31T12:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2021-01-31T13:54:17.410512',
  rank: 0,
  ...props,
})

export const landingPageFactory = (props = {}) => ({
  id: '51b83ac1-4a49-45a5-b333-2aa3d7a2a50b',
  name: 'Cool Landing Page 2.0.032a',
  base_url: '/test-page1231',
  theme: themeFactory(),
  landing_page_contents: [
    landingPageContentFactory('Version en Español'),
    landingPageContentFactory('English Version', {
      vehicles: [
        vehicleFactory({ name: 'walk', text: 'Walker' }),
        vehicleFactory({ name: 'car', text: 'Car', order_index: 1 }),
        vehicleFactory({ name: 'bike', text: 'Bike', order_index: 2 }),
      ],
      language: languageFactory({ id: 'en', name: 'English' }),
      fields: [
        fieldFactory({
          name: 'first_name',
          text: 'First Name',
          order_index: 0,
        }),
        fieldFactory({
          name: 'age',
          text: '8',
          field_type: 'number',
          settings: { datePicker: true },
          order_index: 1,
        }),
        fieldFactory({
          name: 'phone_number',
          text: 'Phone number',
          order_index: 2,
        }),
      ],
    }),
  ],
  country: {
    id: 'de',
    name: 'Germany',
    created_at: '2021-03-15T14:19:19.05739',
    updated_at: '2021-03-15T14:19:19.05739',
    languages: [languageFactory()],
  },
  enabled: false,
  landingPageDomain: 'foodora.com',
  created_by: 'foodora',
  created_at: '2021-01-31T13:54:17.410512',
  updated_by: 'foodora',
  updated_at: '2022-05-10T09:05:43.54004',
  is_compiling: true,
  privacy_policy_url: 'https://www.facebook.com/',
  cookie_policy_url: 'https://www.facebook.com/2',
  ...props,
})
